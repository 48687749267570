import React from 'react';
import Layout from 'components/layout/simple';

export default ({ content }) =>
  <Layout>
    <div id="main">
      <section className="section">
        <div className="inner">
          <header className="major">
            <h1>{content.frontmatter.title}</h1>
          </header>
          <p>{content.frontmatter.description}</p>
        </div>
      </section>

      <section className="section">
        <div className="inner content">
          <div
            className="page-content"
            dangerouslySetInnerHTML={{ __html: content.html }} />
        </div>
      </section>
    </div>
  </Layout>
import React from 'react';
import {graphql} from 'gatsby';

import NotFoundPage from 'components/content/404';

const ErrorPage = ({data: {notFound: content}}) =>
    <NotFoundPage content={content}/>

export default ErrorPage;

export const query = graphql`
    query NotFoundQuery {
        ...NotFoundPage
    }
`
